import React from 'react';

function HelpPage() {
    return (
        <div className="mt-4">
            <center>
                <h2><strong>Help is on its way!</strong></h2>
                <h4>Come back soon...</h4>
            </center>
      </div>
    );
}

export default HelpPage;
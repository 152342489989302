import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

function ChangeLogPage() {

    const [fileContents, setFileContents] = useState([]);
    const [changeLogFileNames, setChangeLogFileNames] = useState([]);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('v');
  

    useEffect(() => {
      fetch("/data/changelog/changelog.json")
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setChangeLogFileNames(data);
      });
    }, []);


    const loadFile = async(fileName) => {
      const path = `/data/changelog/${fileName}`;
      let resp = await fetch(path);

      if(resp.ok) {
        let content = await resp.text();

        if(content && !content.toLowerCase().startsWith("<!doctype html>")) {
          return content;
        }
        return content;
      }

    }

    const fetchFiles = async () => {

      let outFiles = [];

      if(paramValue) {
          //get single

          //get file name
          const fileName = changeLogFileNames.find((val) => paramValue === val.split('/').pop().split('.')[0]);
          console.log("file: " + fileName);
          if(fileName) {
              let content = await loadFile(fileName);
              if(content) {
                  outFiles.push(content);
              }
          }
      } else {
          //get all
          for(let i = 0; i < changeLogFileNames.length; i++) {
              let content = await loadFile(changeLogFileNames[i]);
              if(content) {
                  outFiles.push(content);
              }
          }

          outFiles.reverse();
      }

      return outFiles;
    }

    useEffect(() => {
      const fetchFilesAndSetContents = async () => {
        const files = await fetchFiles();
        setFileContents(files);
      };

      fetchFilesAndSetContents();
    }, [changeLogFileNames]);

    const renderers = {
        // Custom render function for the br node
        br: () => <br />,
      };
  
    return (
      <div className="markdown p-2">
      <h1>Changelog</h1>
            { 
              fileContents.length > 0 ? 
              <>
                {fileContents.map((content) => (
                  <ReactMarkdown renderers={renderers}>{content}</ReactMarkdown>
                ))}
              </>
              :
              <p>No release notes shared yet!</p>
            }
        
            <br/>
            <hr/>
        </div>
    );
}

export default ChangeLogPage;
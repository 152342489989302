import {
    createBrowserRouter,
    Navigate,
    Route,
    Router,
    RouterProvider,
    Routes,
    useLocation
} from "react-router-dom";  
/* Components */
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
/* Pages */
import HomePage from '../pages/HomePage';
import UnknownPage from '../pages/UnknownPage';
import SharePage from '../pages/SharePage';
import ContactPage from '../pages/ContactPage';
import DownloadPage from '../pages/DownloadPage';
import MarkdownPage from '../pages/MarkdownPage';
/* Bootstrap */
import { Container, Row, Col } from 'react-bootstrap';
import ChangeLogPage from "../pages/ChangeLogPage";
import HelpPage from "../pages/HelpPage";

function App() {
    const location = useLocation();
 //   console.log(location.pathname);
    // Access the query parameters
    const queryParams = new URLSearchParams(location.search);
    const inApp = queryParams.get('app') == true;
    let bgColor = null;
    let fgColor = null;

    if(inApp) {
        if(queryParams.has('bg')) {
            bgColor = queryParams.get('bg');
            if(!bgColor.includes("#")) {
                bgColor = "#"+bgColor;
            }
        }

        if(queryParams.has('fg')) {
            fgColor = queryParams.get('fg');
            if(!fgColor.includes("#")) {
                fgColor = "#"+fgColor;
            }
        }
    }

    return (
        <div style={inApp && bgColor && fgColor ? { backgroundColor: bgColor, color: fgColor } : null}>
            {inApp ? <></> : <HeaderComponent/>}
            <Container className="page">
                <Routes>
                    <Route path="/" element={<DownloadPage/>}/>
                    <Route path="/download" element={<DownloadPage/>}/>
                    <Route path="/about" element={<MarkdownPage path="/data/about.md"/>}/>
                    <Route path="/terms" element={<MarkdownPage path="/data/terms-of-service.md"/>}/>
                    <Route path="/community-guidelines" element={<MarkdownPage path="/data/community-guidelines.md"/>}/>
                    <Route path="/privacy" element={<MarkdownPage path="/data/privacy-policy.md"/>}/>
        
        {

        //<Route path="/privacy" element={<MarkdownPage path="/data/privacy-policy.md"/>}/>
}   
        
                    <Route path="/ads" element={<MarkdownPage path="/data/ads.md"/>}/>
                    <Route path="/help" element={<HelpPage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/share/:profile_id/:post_id" element={<SharePage/>}/>
                    <Route path="/share/:profile_id" element={<SharePage/>}/>
                    <Route path="/changelog" element={<ChangeLogPage/>}/>
                    <Route path="*" element={<UnknownPage/>}/>
                </Routes>
            </Container>
            {inApp ? <></> : <FooterComponent/>}
        </div>
    );
}

export default App;
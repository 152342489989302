import { Col, Row, Container } from 'react-bootstrap';

function HeaderComponent() {
    return (
      <footer className="footer-links">
        <Container className="d-flex justify-content-center flex-column flex-sm-row text-center">
          <a href="/terms">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/community-guidelines">Community Guidelines</a>
          <a href="/help">Help</a>
          <a href="/ads">Why Ads?</a>
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
        </Container>
        <Container className="mt-3 text-center">
              <p>Namiax © {new Date().getFullYear()}</p>
        </Container>

  {/* 
<footer>
      <Container fluid className="d-flex justify-content-center">
        <ul className="list-inline footer-links">
          <li className="list-inline-item"><a href="/terms">Terms of Service</a></li>
          <li className="list-inline-item"><a href="/privacy">Privacy Policy</a></li>
          <li className="list-inline-item"><a href="/guidelines">Community Guidelines</a></li>
          <li className="list-inline-item"><a href="/about">About</a></li>
          <li className="list-inline-item"><a href="/contact">Contact</a></li>
        </ul>
      </Container>
      <Container>
        <Row>
          <Col className="text-center">
            <ul className="list-inline footer-links">
              <li className="list-inline-item">Namiax © {new Date().getFullYear()}</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>*/}
    </footer>
    );
}

export default HeaderComponent;
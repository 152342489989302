import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

function SharePage() {

    const DOWNLOAD_URL = "/download";

    const [showLink, setShowLink] = useState(false);
    const { profile_id, post_id } = useParams();

    useEffect(() => {
        appRedirectCallback();
      }, []);  
    
    const getDeviceType = () => {
        var userAgent = navigator.userAgent;

        // Check for iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'ios';
        }

        // Check for Android
        if (/Android/.test(userAgent)) {
            return 'android';
        }

        // Return 'Other' for unknown devices
        return 'other';
    };

    const appRedirectCallback = () => {
        var deepLink;

        switch(getDeviceType()) {
            default:
                setShowLink(false);
                deepLink = DOWNLOAD_URL;
                break;
            case 'ios':
            case 'android':
                setShowLink(true);
                if(post_id) {
                    deepLink = `comnamiaxhub-viewpost://?p=${profile_id}&i=${post_id}`;
                } else {
                    deepLink = `comnamiaxhub-viewprofile://?p=${profile_id}`;
                }
                break;
        }

//        window.location.href = deepLink;

        window.open(deepLink, '_self');
    };



    const handleOnClick = () => {
        appRedirectCallback();

        //auto redirect after x time in case app isnt installed. 
        setTimeout(() => {
            window.open(DOWNLOAD_URL, '_self');
        }, 3000);
    };

    return (
        <Row style={{height: "80vh", justifyContent: 'center', alignItems: 'center'}}>
            <center>
                <a style={{border: 'solid', borderWidth: 1, padding: 8, borderRadius: 4, borderColor:'white'}} onClick={handleOnClick}>Launch App</a>
            </center>
        </Row>
    );
}

export default SharePage;
function HomePage() {
   
    return (
        <>

        <p>Home</p>
        </>
    );
}

export default HomePage;
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from 'react-bootstrap';

function MarkdownPage({path}) {

    const [markdownContent, setMarkdownContent] = useState('');

    useEffect(() => {
      fetch(path)
        .then(response => response.text())
        .then(content => setMarkdownContent(content));
    }, []);

    const renderers = {
        // Custom render function for the br node
        br: () => <br />,
      };
  
    return (
        <div className="markdown p-2">
            <ReactMarkdown renderers={renderers}>{markdownContent}</ReactMarkdown>
            <br/>
            <hr/>
        </div>
    );
}

export default MarkdownPage;
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Import Bootstrap components

const PreregisterEmailModalComponent = ({ show, handleClose, onSubmit }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the onSubmit function passed from the parent component
    if (onSubmit) {
      onSubmit(event.target.email.value);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Pre-Register Now!</Modal.Title>
    </Modal.Header>
    <Modal.Body >
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>We're not quite ready yet. Simply enter your email below, and we'll notify you the moment it's ready for download!</Form.Label>
          <Form.Control type="email" placeholder="Email Address"  className="my-4" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
  );
};

export default PreregisterEmailModalComponent;
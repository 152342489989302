import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PreregisterEmailModalComponent from "../components/PreregisterEmailModalComponent";

function DownloadPage() {

    /* Modal */
    const [showModal, setShowModal] = useState(false);

    const handleDownloadLink = (url) => {
        window.open(url, "_blank");
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = (email) => {
        // Close the modal after handling the email submission
        handleCloseModal();
    };

    /* Links */

    const appStoreLink = "https://www.google.com";
    const googlePlayLink = "https://play.google.com/store/apps/details?id=com.namiax.hub";

    /* Descriptions for Promo */
    const promos = [
        {
            img:"/images/promo_home.png",
            title: "Discover Groups",
            message: "Explore a diverse range of community created groups! Find groups for just about anything and share your thoughts."
        },
        {
            img:"/images/promo_question.png",
            title: "Ask Questions",
            message: "Connect and get answers! Ask questions directly to users or in community groups."
        },
        {
            img:"/images/promo_anonymous.png",
            title: "Post Anonymously",
            message: "Anonymity unleashed! Discover the freedom of authentic expression by sharing your thoughts without revealing your identity."
        }
    ];


    return (
        <div className="p-4">
            {showModal ? <PreregisterEmailModalComponent show={showModal} handleClose={handleCloseModal} onSubmit={handleSubmit}/> : <></>}
            <Row style={{marginBottom: 50}}>
                <Col xs={{span:8, offset:2}} sm={{span:6, offset:3}} md={{span:10, offset: 1}} lg={{span:6, offset: 3}} xl={{span:4, offset: 4}}>
                    <Row>
                        <Col xs={12} md={6} className="text-center mb-4">
                            <a>
                                <img onClick={() => setShowModal(true)} className="img-download" src="./images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg" fluid/>
                            </a>
                        </Col>
                        <Col xs={12} md={6} className="text-center mb-4">
                            <a onClick={() => handleDownloadLink(googlePlayLink)}>
                                <img className="img-download" src="./images/Google_Play_Store_badge_EN.svg"/>
                            </a>
                        </Col>
                    </Row>  
                </Col>
            </Row>
            <Row className="justify-content-center">
                {promos.map((row, index) => {
                    return(
                            
                        <Col key={index} xs={10} md={5} lg={3} className="promo-item">
                            <Row className="mb-2">
                                <img src={row.img} alt={row.title} width={'100%'}/>
                            </Row>
                            <Row className="my-4 text-center">
                                <h3>{row.title}</h3>
                                <p>{row.message}</p>
                            </Row>
                        </Col>
                    )})
                }
            </Row>
        </div>
    );
}

export default DownloadPage;
import React from 'react';
import ContactFormComponent from "../components/ContactFormComponent";

function ContactPage() {
    return (
        <div>
            <ContactFormComponent/>
      
      </div>
    );
}

export default ContactPage;
import React from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";

const ContactFormComponent = ({ onSubmit }) => {
    return(
        <Col className="py-4" xs={12} lg={{span:10, offset:1}} xl={{span: 8, offset: 2}}>
                <h2><strong>Contact Us</strong></h2>
                <Form>
                <Form.Group controlId="formName" className="my-4">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" required />
                </Form.Group>

                <Form.Group controlId="formEmail" className="my-4">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required/>
                </Form.Group>

                <Form.Group controlId="formMessage" className="my-4">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Enter your message" required/>
                </Form.Group>

                <Button variant="primary" type="submit">
                Submit
                </Button>
            </Form>
        </Col>
    );
}

export default ContactFormComponent;
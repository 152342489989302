import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

function UnknownPage() {
    return (
        <Row>
            <Col xs={{span:10, offset:1}} sm={{span:8, offset:2}} lg={{span:4, offset:4}}>
                <img width="100%" src="/images/404-invert.svg" fluid/>
            </Col>
        </Row>
    );
}

export default UnknownPage;
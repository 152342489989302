import { Navbar, Nav, Container } from 'react-bootstrap';

function HeaderComponent() {
    return (
        <Navbar bg="transparent" expand="sm">
        <Container  style={{marginBottom:40}}>
            
            <Navbar.Brand href="/">
                <img
                    src="/images/logo-invert.svg"
                    alt=""
                    height="36"
                    className="d-inline-block align-top mx-3"
                />
            </Navbar.Brand>
            
            <Navbar.Toggle className="nav-toggle-btn" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">                
            <Nav className="mr-auto">
                    <Nav.Link href="/download">Download</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default HeaderComponent;
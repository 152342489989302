import React from 'react';
import ReactDOM from 'react-dom/client';

/* Styling */
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './components/App';
import { BrowserRouter, Route, Router, Routes, useLocation } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));


function RouteManager() {
    const location = useLocation();

    // You can perform any logic or checks based on the current location here

    return <App location={location} />;
}

root.render(
    <BrowserRouter>
      <App/>
    </BrowserRouter>
);
